.mainLoader { 
    display: flex;
    justify-content: center;
    height: 100vh;
}
.loader{ 
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
}
.loader::after{
    border-color: #a3f82b transparent transparent !important;
}