.radiobtn {
  position: relative;
  display: block;
}
.radiobtn label {
  display: block;
  background: white;
  color: #444;
  border-radius: 5px;
  padding: 10px 20px;
  border: 2px solid #ced4da;
  margin-bottom: 5px;
  cursor: pointer;
}
.radiobtn label:after,
.radiobtn label:before {
  content: "";
  position: absolute;
  right: 30px;
  top: 11px;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  background: white;
  border-radius: 50%;
  border: 1px solid gray;
}
.radiobtn label:before {
  background: transparent;
  transition: 0.1s width cubic-bezier(0.075, 0.82, 0.165, 1) 0s,
    0.3s height cubic-bezier(0.075, 0.82, 0.165, 2) 0.1s;
  z-index: 2;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: center;
  width: 0;
  height: 0;
  background-image: url("./../images/circle-24.png");
}
.radiobtn input[type="radio"] {
  display: none;
  position: absolute;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.radiobtn input[type="radio"]:checked + label {
  background: #007bff;
  -webkit-animation-name: blink;
  animation-name: blink;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  color: white;
  border-color: #007bff;
}
.radiobtn input[type="radio"]:checked + label:after {
  background: white;
  border-radius: 50%;
  /* color: white; */
}
.radiobtn input[type="radio"]:checked + label:before {
  width: 20px;
  height: 20px;
}
/*
@-webkit-keyframes blink {
  0% {
    background-color: #fdcb77;
  }
  10% {
    background-color: #fdcb77;
  }
  11% {
    background-color: #fdd591;
  }
  29% {
    background-color: #fdd591;
  }
  30% {
    background-color: #fdcb77;
  }
  50% {
    background-color: #fdd591;
  }
  45% {
    background-color: #fdcb77;
  }
  50% {
    background-color: #fdd591;
  }
  100% {
    background-color: #fdcb77;
  }
}

@keyframes blink {
  0% {
    background-color: #fdcb77;
  }
  10% {
    background-color: #fdcb77;
  }
  11% {
    background-color: #fdd591;
  }
  29% {
    background-color: #fdd591;
  }
  30% {
    background-color: #fdcb77;
  }
  50% {
    background-color: #fdd591;
  }
  45% {
    background-color: #fdcb77;
  }
  50% {
    background-color: #fdd591;
  }
  100% {
    background-color: #fdcb77;
  }
} */

.jumbotron {
  background-color: #f3f7fc;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(129, 129, 129, 0.97);
  -moz-box-shadow: 0px 0px 5px 0px rgba(129, 129, 129, 0.97);
  box-shadow: 0px 0px 5px 0px rgba(129, 129, 129, 0.97);
}

@media only screen and (min-width: 992px) {
  /* For mobile phones: */
  .static_pages_btn {
    width: auto;
    border-radius: 0.4em !important;
  }
}

@media only screen and (min-width: 313px) {
  .landing-form .form-control {
    margin-top: 0%;
  }
}

@media only screen and (min-width: 450px) {
  .landing-form .form-control {
    margin-top: 0%;
  }
}

.page-heading {
  font-size: 2.3em;
  font-weight: 700;
  font-family: "Work Sans", sans-serif;
}
.table-contentful{
  width: 100%;
  border: 1px solid #373f41;
}
.table-row-contentful {
  height: 35px;
  width: 20px;
  text-align: center;
  vertical-align: middle;
  border-bottom: 1px solid #373f41;
}
.p-heading {
  font-family: "Work Sans", sans-serif;
  letter-spacing: 3px;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.2;
  color: inherit;
}

.paragraph {
  font-size: 1.2em;
  font-family: "Work Sans", sans-serif;
}

.generic_font_color {
  color: #373f41;
  padding: 0 150px;
}
@media only screen and (max-width: 1080px) {
  .generic_font_color {
    padding: 0 100px;
  }
}

@media only screen and (max-width: 768px) {
  .generic_font_color {
    padding: 0 50px;
  }
}

@media only screen and (max-width: 500px) {
  .generic_font_color {
    padding: 0 24px;
  }
  .page-heading {
    font-weight: 500;
    font-size: 32px;
    font-family: "Work Sans", sans-serif;
    font-style: normal;
    letter-spacing: normal;
  }
  .p-heading {
    font-weight: 400;
    font-size: 24px;
    font-style: normal;
    font-family: "Work Sans", sans-serif;
    letter-spacing: normal;
  }
  .paragraph {
    font-weight: 400;
    font-size: 18px;
    font-style: normal;
    font-family: "Work Sans", sans-serif;
    letter-spacing: normal;
  }
}
.mobile {
  display: none;
}


.compare_insurance_btn:hover {
  cursor: pointer;
}

.compare_insurance_btn_link:hover {
  text-decoration: none;
}

.main-input-div {
  max-width: 234px;
}

.mobile-form {
  display: none;
}
@media only screen and (max-width: 575px) {
  /* For mobile phones: */
  .main-input-div {
    max-width: 100%;
  }

  .desktop-form {
    display: none;
  }
  .mobile-form {
    display: block;
  }
  .mobile-button {
    width: 100%;
    height: 71.41px;
  }
  .mobile-button-submit {
    width: 100%;
    height: 71.41px;
  }
  .mobile-button-back {
    height: 71.41px;
  }
}
