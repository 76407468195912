

.app {
    max-width: 300px;
    margin: 0 auto;
}

.app i {
    font-size: 80px;
    animation-duration: 3s;
    animation-name: slidein;
    animation-iteration-count: 1;
}

article {
    position: relative;
    width: 200px;
    height: 110px;
    margin: 5px;
    float: left;
    border: 2px solid #50bcf2;
    box-sizing: border-box;
}

article div {
    width: 200px;
    height: 110px;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 25px;
    transition: .5s ease;
}

article input {
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    height: 110px;
    opacity: 0;
    cursor: pointer;
}

input[type=checkbox]:checked ~ div {
    background-color: #50bcf2;
}

.inputes{
    display: flex;
}

.input_container{
    width: 50%;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
.content_container{
    display: flex;
    justify-content: center;
}
.container_gap{
    margin-top: 50px;
}
.displaying_content{
    justify-content: center;
    display: flex;
    /* flex-direction: column; */
    flex-wrap: wrap;
}
@keyframes slidein {
    from {
        margin-top: 100%;
        width: 300%;
    }

    to {
        margin: 0%;
        width: 100%;
    }
}
@media (max-width: 995px){
    .input_container{
        width: 100%;
    }
}