* {
  margin: 0%;
  box-sizing: border-box;
  font-family: "Work Sans", sans-serif;
}
:root {
  --pri: #68c31e;
  --sec: #0d72b5;
  --txt: #ffffff;
}
body {
  padding: 0% !important;
  margin: 0px 0px !important;
  overflow-x: hidden;
  touch-action: pan-y pinch-zoom;
  font-family: "Work Sans", sans-serif;
}
#head {
  padding-left: 4em;
  width: 100vw;
  padding-right: 2em;
  background-color: rgb(255, 255, 255) !important;
}
#head-half {
  padding-left: 4em;
  width: 100vw;
  padding-right: 2em;
  background-color: rgb(255, 255, 255) !important;
}
#logo-head {
  margin-right: 4em;
}
.navbar-nav ul .mr-auto {
  padding-left: 15% !important;
}
.navbar-light .navbar-toggler {
  border-color: #ffffff;
}

.landing-form {
  width: 60%;
}
.landing-form .form-control {
  border-left: 2px solid #90cf36;
  border-top: 2px solid #90cf36;
  border-right: 2px solid #90cf36;
  background-color: #ebebec;
  border-radius: 5px !important;
  padding: 15px 30px;
  margin-top: -7%;
  font-family: "Inter", sans-serif;
}
.newBkg{
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 200px;
  height: 50px;
  cursor: pointer;
  margin-right: 4em;
  transition: 1s ease-in-out;
}
.newBkg:hover {
  transform: scale(1.1);
}
.zipError {
  border: 2px solid red !important;
}
.css-6q0nyr-Svg {
  fill: green !important;
  stroke: green !important;
}
.landing-form .form-group input {
  height: 70px !important;
}
.landing-form button {
  height: 67px !important;
}
input#zip-gm {
  background-image: url("../images/fonts/marker.png");
  background-size: 14px;
  background-position-x: 15px;
  background-position-y: 20px;
  background-repeat: no-repeat;
  text-indent: 20px;
}

input#zip-gm:focus {
  background-image:none;
  text-indent:0px
}
/* .landing-form .form-group {
  height: 70px !important;
} */
@media only screen and (max-width: 595px){
  .strong{
    padding-left: 0em !important;
  }
}
@media only screen and (max-width: 782px) {
  body {
    overflow-x: hidden;
  }
  #head {
    padding-left: 1em;
    padding-right: 1em;
    margin-right: 1em;
  }
  .sec-compare-heading {
    width: 90% !important;
    font-weight: 600 !important;
    font-style: normal !important;
    font-family: 'Work Sans' !important;
    font-size: 36px !important;
    margin: 0% auto !important;
  }
  .header-sec {
    flex-direction: column !important;
    row-gap: 2em;
    padding: 2em 6em !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .menu-item li {
    column-gap: 1em !important;
  }
  .landing-form {
    width: 100%;
  }
  /* .input-icon {
    margin-left: 0.2em !important;
  } */
  #head-btn {
    margin-left: -0.8em !important;
  }
  .info-sec-two .content {
    margin-left: -9em !important;
    padding-top: 4em !important;
    width: 100%;
  }
  .info-sec-two {
    margin-top: 0% !important;
  }
  #content-heading {
    width: 100% !important;
  }

  .info-sec-three .content {
    padding-top: 4em !important;
  }
  .line-overlap {
    width: 100% !important;
    height: 7em !important;
    margin: 1em auto !important;
    padding: 1em auto !important;
  }
  .offers,
  .offer-items {
    width: 100vw !important;
    margin-left: -15px !important;
  }
  .notify-sec {
    width: 100vw !important;
    margin-left: -15px !important;
    padding: 2em 2em !important;
    height: auto !important;
    column-gap: none !important;
  }
  #notify-btn {
    width: 60%;
    /* padding: 1em; */
    /* padding-top: 1.2em; */

  }
  .shadow{
    -webkit-box-shadow: 1px 2px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 1px 2px 5px 0px rgba(0,0,0,0.75);
box-shadow: 1px 2px 5px 0px rgba(0,0,0,0.75);
  }
  .notify-sec h3 {
    font-size: 1.1em;
    font-weight: 700 !important;
    padding-top: 0.7em !important;
  }
  .footer {
    padding-top: 2em;
    display: flex;
    flex-wrap: wrap;
  }
  #one,
  #two,
  #three,
  #four {
    padding-bottom: 0% !important;
    margin: 0% !important;
    padding-top: 1em !important;
    text-align: center;
  }
  .strong {
    text-align: center;
    padding-left: 2.1em;
  }
  .copyright p {
    width: 70% !important;
    margin: 0% auto;
    text-align: center;
    padding-top: 2em;
    padding-left: 2em;
  }
  .info-sec-one .img-sec {
    margin-top: 3em;
  }
  nav {
    padding: 0em;
    margin: 0%;
  }
  #logo-head {
    width: 9em !important;
    height: auto !important;
  }
  .landing .staic_pages_landing {
    margin: 0% !important;
    height: auto !important;
  }
  .sec-compare {
    margin: 0% !important;
  }
  .main-btn {
    /* width: 15em; */
    margin-left: 3.8em !important;
  }
  .offers {
    margin: 0% !important;
  }
  .offer-content {
    width: 90% !important;
    margin: 0% auto !important;
  }
  .offer-items,
  .item-box {
    margin-left: -7px !important;
    padding-left: 0px !important;
  }
  .notify-sec {
    margin-left: 0px !important;
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    row-gap: 1em !important;
  }
  .notify-sec h3 {
    text-align: center;
  }
  #notify-btn {
    width: 100%;
    text-align: center;
    margin-left: 0.2em !important;
  }
  .footer {
    /* margin-left:  0% !important; */
    margin: 0% !important;
  }
  #one {
    margin-left: -1em !important;
  }
  .copyright p {
    margin-left: 3.3em !important;
    margin-top: -5px !important;
  }
}

@media only screen and (max-width: 991px) {
  .header-btn {
    margin-top: 2em;
    margin-bottom: 1em;
  }
  #navbarSupportedContent {
    margin-top: 2em;
  }
  .notify-sec {
    padding: 1em 2em;
  }
  .notify-sec h3 {
    font-size: 1.4em;
    font-weight: 700;
  }
  #one {
    padding-left: 1.2em !important;
    transition: 0.4s ease-in-out;
  }
}

@media (min-width: 330px) {
  #head-half {
    padding-left: 4em;
    padding-right: 3em;
  }
  .if-form .radios {
    background-color: white;
    padding: 5px;
    width: 100%;
    margin-top: 5px;
    border-radius: 5px;
  }
  .if-form .radio-active {
    background-color: #1e90ff;
    color: white;
  }
}
@media (min-width: 730px) {
  #head-half {
    padding-left: 1em;
    padding-right: 1em;
  }
  .if-form .radios {
    background-color: white;
    padding: 5px;
    width: 100%;
    margin-top: 5px;
    border-radius: 5px;
  }
  .if-form .radio-active {
    background-color: #1e90ff;
    color: white;
  }
}
@media (min-width: 930px) {
  #head-half {
    padding-left: 33%;
  }
  .if-form .radios {
    background-color: transparent;
    padding: 0;
    width: 100%;
    margin-top: 0;
    border-radius: 0;
  }
  .if-form .radio-active {
    background-color: transparent;
    color: black;
  }
}
.offer-data .offer-data-heading a {
  color: #016ab0;
}
.offer-data .offer-data-heading:hover {
  color: #4263eb !important;
  text-decoration: underline;
}
.footer > * {
  flex: 1 1 20em;
}
.header-sec {
  display: flex;
  flex-direction: row;
  column-gap: 7em;
  padding: 1em 10em;
  margin: 0;
  justify-content: center;
  align-self: center;
  width: 100%;
}
#btn-phone {
  width: 0.8em;
}
#logo-head {
  width: 13em;
  height: 3em;
}
a {
  text-decoration: none;
  color: grey;
}
#link-img {
  width: 0.7em;
}
.menu-item {
  list-style-type: none;
  display: flex;
  column-gap: 2em;
  color: grey;
  padding-top: 1em;
  font-size: 0.7em;
}
.btn-contact {
  width: 123px;
  text-align: center;
  display: flex;
  flex-direction: column;
}
.btn-payment{
  cursor: pointer;
  background-color: #54bc00 !important;
}
.makePaymentSpan{
  color: #fff;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 17px;
}
.btn-contact p {
  font-size: 11px;
  padding-top: 0.6em;
  margin-bottom: 0em;
}
.btn-contact h4 {
  font-size: 0.9em;
  font-weight: 700;
}
.header-btn {
  display: flex;
  flex-direction: row;
  column-gap: 1em;
  border-radius: 4px;
  width: 10em;
  padding: 0.2em 1em;
  margin-right: 3em;
  border: 2px solid var(--pri);
}
.landing {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.landingImg{
  min-height: 60vh;
}

.commercial-auto-insurance-bg-image {
  min-height: 60vh;
}
.sr-22-insurance-bg-image {
  min-height: 60vh;
}
.apartment-renters-bg-image {
  min-height: 60vh;
}
.homeowner-isurance-bg-image {
  height: 60vh;
}
.motorcycle-isurance-bg-image {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(../images/motorcycle-min.jpg);
  min-height: 60vh;
}

.auto-isurance-bg-image {
  min-height: 60vh;
}
.nonowner-isurance-bg-image {
  min-height: 60vh;
}
.dui-isurance-bg-image {
  min-height: 60vh;
}

.payment-online-bg-image {
  min-height: 60vh;
}

.roadside-bg-image {
  min-height: 60vh;
}

.ourmission-bg-image {
  min-height: 60vh;
}
.ourhonesty-bg-image {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(../images/ourmission.png);
  min-height: 60vh;
}
.career-bg-image {
  min-height: 60vh;
}
.privacy-bg-image {
  min-height: 60vh;
}

.staic_pages_landing {
  width: 100vw;
  display: flex;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.landing-para {
  padding-top: 5%;
  text-align: center;
  font-size: 18px;
  color: white;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 0;
}
.landing-heading {
  color: white;
  font-weight: 500 !important;
  font-size: 65px;
  line-height: 84px;
  text-align: center;
}
.color-head {
  color: #a3f82b !important;
  font-weight: 700;
}
.landing2-heading {
  color: white;
  text-align: center;
  font-weight: 700;
  font-size: 45px;
  line-height: 56px;
}
.main-headings-sec {

  padding: 4em 4em;
}
.main-input {
  padding: 1.6em 1em 1.6em 1em;
  border-radius: 0.4em;
  width: 100%;
  border: none;
  margin-right: 1em;
  border: 2px solid var(--pri);
  outline: none;
  background-color: #ececed;
}

.m-input {
  padding: 1.2em 0.9em 1.2em 2.2em;
  border-radius: 0.4em;
  border: none;
  padding-left: 2.6em !important;
  margin-right: 1em;
  border: 2px solid var(--pri);
  outline: none;
  width: 100%;
  background-color: #ececed;
}
select {
  padding: 0.7em 2.4em;
  border-radius: 0.4em;
  border: none;
  margin-right: 1em;
  border: 2px solid var(--pri);
  outline: none;
}
.main-btn {
  transition: 0.2s ease-in-out;
  background-color: var(--pri);
  color: var(--txt);
  border: none;
  padding: 0.6em 1.5em;
  border-radius: 3px;
  outline: none;
}
.m-btn {
  transition: 0.2s ease-in-out;
  background-color: var(--pri);
  color: var(--txt);
  border: none;
  padding: 1.2em 1.5em;
  border-radius: 3px;
  outline: none;
}
.sec-btn {
  color: var(--pri);
  transition: 0.2s ease-in-out;
  border: 2px solid var(--pri);
  padding: 0.5em 1.5em;
  border-radius: 3px;
  outline: none;
  margin-left: 1em;
}
#head-btn {
  padding-top: 1.3em;
  padding-bottom: 1.3em;
}
.sec-btn:hover {
  transform: scale(1.06);
  transition: 0.2s ease-in;
  cursor: pointer;
  background-color: var(--pri);
  color: var(--txt);
}
.main-btn:hover {
  transform: scale(1.06);
  transition: 0.2s ease-in;
  font-weight: 600;
  cursor: pointer;
}

.sec-compare {
  background-color: #ddf0fc;
  display: flex;
  flex-direction: column;
  width: 100vw;
  margin: 0% auto;
  /* margin-left: -15px; */
  column-gap: 2em;
  height: auto;
  padding: 3em 0em;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.sec-para {
  text-align: center;
  width: 86%;
  margin: 0% auto;
  margin-top: 2em;
}
.sec-compare-heading {
  font-size: 2.3em;
  font-weight: 700;
}
#loc-icon {
  width: 13px;
  height: auto;
  position: absolute;
  left: 1.8em;
  top: 1.4em;
  z-index: 100;
}
.input-icon {
  height: auto;
  position: relative;
}

.selectdiv {
  position: relative;
  float: left;
  min-width: 200px;
  margin: 50px 33%;
  padding-right: 200px;
}
#main-dp {
  appearance: none;
  position: relative;
  background-color: #ececed;
  padding-right: 6em !important;
  padding: 1.2em 2em;
}
#drp-icon {
  margin: 9px 0px;
  width: 13px;
  height: auto;
  position: absolute;
  right: 2.5em;
  top: 1.1em;
  z-index: 1;
}
.dropdown {
  position: relative;
}
.dash {
  width: 6em;
  height: 0.6em;
  background-color: #f03d3d;
  margin-bottom: 2em;
  margin-top: 1em;
}
.content-para {
  margin-bottom: 2em;
  font-size: 0.9em;
  /* width: 85%; */
}
#content-heading {
  letter-spacing: 3px;
}
.info-sec-two #content-heading {
  width: 20em;
}
.content {
  width: 50%;
  padding: 1em;
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
}
.info-sec-one,
.info-sec-two,
.info-sec-three {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding: 1em;
}
.info-sec-two {
  flex-direction: row-reverse;
  margin-top: -3em;
}
.info-sec-two .content {
  padding-left: 10em;
  margin-top: -1em !important;
}
.info-sec-three {
  margin-top: -2em;
}
.info-sec-three .content {
  padding-left: -10em;
  margin-top: -1em !important;
}
.info-sec-one > * {
  flex: 1 1 20em;
}
.info-sec-two > * {
  flex: 1 1 20em;
}
.info-sec-three > * {
  flex: 1 1 20em;
}
.info-sec-four > * {
  flex: 1 1 20em;
}
.img-sec {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.line-overlap {
  margin-top: -2em;
  width: 100%;
  display: flex;
  justify-content: center;
  align-self: center;
  height: 13em;
}
#ov-img {
  width: 50%;
}
.offers {
  width: 100vw !important;
  margin-left: -15px;
  background-color: #f8f9fb;
  display: flex;
  justify-content: center;
  align-self: center;
  text-align: center;
  padding-top: 4em;
  /* padding-bottom: 3em; */
}
.offer-heading {
  margin-bottom: 1em;
  font-size: 2em;
}
.offer-items {
  z-index: 99;
  margin-left: -100px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #f8f9fb;
  justify-content: space-between;
  padding-bottom: 5em;
}
.offer-items > * {
  flex: 1 1 0em;
}
#imgForIcon {
  width: 5em;
  height: 6em;
  height: auto;
}
#imgForIcon1 {
  width: 4em;
}
.icon-box {
  display: flex;
  justify-content: center;
  left: -8em;
}
.item-box {
  margin-top: 3em !important;
  margin: 0% auto;
  width: 100%;
  width: 100%;
  cursor: pointer;
  align-self: center;
  margin-left: 5em;
  margin-right: 4em;
  align-items: center;
}
.offer-data {
  display: flex;
  justify-content: center;
}
.offer-data1 {
  width: 100%;
  padding-left: 1em;
}
.offer-data-para {
  margin-top: 1em;
}
.notify-sec h3 {
  -webkit-appearance: initial;
  padding-right: 50px;
}

.notify-sec {

  width: 100vw;
  display: flex;
  flex-direction: row;
  margin: 0% auto;
  justify-content: center;
  align-self: center;
  margin-left: -15px;
  column-gap: 3em;
  padding-top: 3em;
  padding-bottom: 3em;
  background-color: #006AB0;
}
.notify-sec h3 {
  color: white;
  padding-top: 0.3em;
  font-weight: 400;
}

.column {
  float: left;
  width: 25%;
  padding: 5px;
}

.footer:after {
  content: "";
  display: table;
  clear: both;
}

.footer {
  background-color: #132042;
  color: white;
  /* margin-left: -15px;
  margin-right: -15px; */
}
.footer * li{
  font-size: 14px;
}

.footer-links-headin{
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
}

#logo-footer {
  width: 12em;
}
#one {
  margin-top: 6em;
  padding-bottom: 10em;
  padding-left: 3em;
}
#two,
#three,
#four {
  margin-top: 6em;
  padding-bottom: 5em;
}
#links-footer {
  margin-top: 2em;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  row-gap: 1em;
  color: #5c718d;
  font-size: 0.7em;
}
.strong {
  padding-left: 2.5em;
}
.copyright {
  width: 100%;
  background-color: #132042;
}
.copyright p {
  margin: 0% auto;
  padding-bottom: 2em;
  color: white;
  font-weight: 400;
  font-size: 0.8em;
  text-align: center;
  background-color: #132042;
}
.form-bg {
  background-color: #f3f7fc;
  padding-bottom: 100px;
}
.form-bg .container {
  width: 60%;
}
.form-bg h1 {
  font-weight: 600;
}
.form-bg .if-hr {
  background-color: #212429;
  margin-bottom: 50px;
}
.form-bg .if-disclaimer {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.5px;
  margin-top: 40px;
  text-align: justify;
}
.if-form .form-group label {
  font-family: "Inter", sans-serif !important;
  color: #323232 !important;
  font-weight: 500 !important;
  top: 0.5rem !important;
  font-size: 12px !important;
}
.if-form .form-control {
  background-color: #ffffff;
  border-radius: 5px !important;
  /* padding: 15px 10px; */
}
.if-form .form-group input {
  height: 48px !important;
}
.if-form .form-group select {
  height: 46px !important;
}
.if-form .form-group select option {
  padding: 10px;
}
.if-form .add-btn {
  background-color: #ffffff !important;
  border: 2px solid #007bff;
  color: #007bff;
}
.if-form .add-btn .btn-icon {
  position: relative;
  display: inline-block;
  left: -6px;
  top: 3px;
}
.if-form .add-btn .btn-text {
  position: relative;
  top: -4px;
}
.if-form-head {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.15px;
  margin-top: 35px;
  margin-bottom: 35px;
}
/* .if-form .radios {
  background-color: transparent;
  padding: 0;
  width: 0;
  margin-top: 0;
  border-radius: 0;
} */
.btn-gm-green {
  -webkit-appearance: initial;
  font-family: "Inter", sans-serif;
  background-color: #54bc00 !important;
  padding: 10px 30px;
  text-transform: none;
}
.save-m-btn{
  font-size: 22px;
  font-weight: 700;
}
.btn-gm-outline-green {
  font-family: "Inter", sans-serif;
  border: #54bc00 1px solid !important;
  padding: 10px 25px;
  text-transform: none;
}
.btn-gm-blue {
  font-family: "Inter", sans-serif;
  background-color: #006ab0 !important;
  padding: 10px 30px;
  text-transform: none;
}
.btn-gm-blue span {
  font-size: 12px;
  margin-right: 5px;
}
.btn-gm-outline-blue {
  font-family: "Inter", sans-serif;
  border: #006ab0 1px solid !important;
  padding: 10px 30px;
  text-transform: none;
}
.btn-home-radius {
  border-radius: 3px !important;
  width: 100%;
  padding: 15px;
  margin-top: 1.2%;
}
@media (min-width: 990px) {
  .btn-home-radius {
    margin-top: 1.2%;
  }
}
@media (min-width: 1020px) {
  .btn-home-radius {
    margin-top: 5.2%;
  }
}
@media (min-width: 1080px) {
  .btn-home-radius {
    margin-top: 2.2%;
  }
}
@media (min-width: 1300px) {
  .btn-home-radius {
    margin-top: 1.2%;
  }
}

.btn-gm-secondary {
  font-family: "Inter", sans-serif;
  color: #323232 !important;
  background-color: transparent;
  border-color: transparent;
  text-transform: none;
}
.btn-gm-secondary span {
  font-size: 12px;
  margin-right: 5px;
}
.step-container {
  width: 80%;
  margin: 50px auto;
  height: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.step-wrapper {
  display: flex;
  flex-direction: row;
  position: relative;
  width: fit-content;
  margin-bottom: 50px;
}
.step-container .step-wrapper p {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 1rem;
  color: #000;
  margin-left: -25px;
  font-size: 11px;
  width: 60px;
}
.bar {
  position: absolute;
  height: 2px;
  width: 50%;
  top: 20px;
  left: 25%;
  margin: 0% auto;
  color: #006ab0;
  /* z-index: -0; */
  background-color: #006ab0;
}
.point {
  background-color: white;
  border: 1px solid #006ab0;
  margin: 0em 6em;
  padding: 1em;
  z-index: 1;
  width: 45px;
  height: 45px;
  color: white;
  border-radius: 50%;
}
.point span {
  color: #006ab0 !important;
  /* padding: 1px 6px; */
  display: block;
  margin-top: -8px;
  margin-left: 2px;
}

.contact-us-form h1 {
  font-family: "Work Sans", sans-serif;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0.25px;
  margin-bottom: 5%;
}

.contact-us-form .addresses {
  font-family: "Work Sans", sans-serif;
  font-weight: 400;
  line-height: 40px;
  color: #373f41;
}
@media all and (max-width: 360px) {
  .mleft20 {
    margin-left: 1em;
  }
}
.contact-us-form .timings {
  font-family: "Work Sans", sans-serif;
  font-weight: 400;
  line-height: 40px;
  color: #373f41;
  margin-top: 9%;
}

.contact-us-form .form-group {
  padding-top: 0;
}

.non-owner-if-form .form-group label {
  font-family: "Inter", sans-serif !important;
  color: #707070 !important;
  font-weight: 500 !important;
  margin-bottom: 10px !important;
  font-size: 11px !important;
}
.non-owner-if-form select.form-control:not([size]):not([multiple]) {
  height: calc(3rem + 2px) !important;
}
.non-owner-if-form .form-control {
  background-color: #ffffff;
  border-radius: 5px !important;
  /* border: 1px solid #b8b6b6; */
  padding: 15px 10px;
  margin-top: 5px;
}
.non-owner-if-form .custom-file-control,
.form-control,
.is-focused .custom-file-control,
.is-focused .form-control {
  background-image: none;
  border-color: none;
}
.non-owner-if-form .add-btn {
  background-color: #ffffff !important;
}
.non-owner-if-form .add-btn .btn-icon {
  position: relative;
  display: inline-block;
  left: -6px;
  top: 3px;
}
.non-owner-if-form .add-btn .btn-text {
  position: relative;
  top: -4px;
}

.steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 4rem;
  margin: 0 0 2rem 0;
}

.step {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 1rem 0.5rem;
  transition: 0.5s;
  border-radius: 4px;
  user-select: none;
}
.steps .active {
  background-color: #fff;
  color: #000;
}

.steps .active span {
  background-color: #fff;
  color: #000 !important;
}

.step:active {
  background-color: rgba(0, 0, 0, 0.2);
  transition: 0.02s;
}

.step:active .label {
  color: rgb(29, 29, 29);
}

.step .icon {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.3rem;
  height: 2.3rem;
  background-color: rgba(184, 176, 176, 0.38);
  color: rgb(99, 99, 99);
  margin-right: 0.5rem;
}
.step.active .icon,
.step.complete .icon {
  background-color: #92989c;
}

.step-divider {
  flex: 1;
  border-top-style: solid;
  border-color: #bdbdbd;
  border-top-width: 1px;
  margin-bottom: 10px;
}

.step .label {
  color: rgb(180, 180, 180);
}

.step.active .label {
  color: rgb(29, 29, 29);
}

@media only screen and (max-width: 685px) {
  /* For mobile phones: */
  .landing-heading {
    font-family: Work Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
  }
  .description1 {
    font-size: 20px !important;
  }
  #content-heading {
    font-size: 36px;
    font-weight: 600;
  }
  .content-para {
    font-size: 24px;
    font-weight: 400;
  }
  .btn-home-radius {
    margin-bottom: 10px;
  }
  .sec-compare-heading,
  .offer-heading {
    font-size: 36px;
    font-weight: 600;
  }
  .sec-para,
  .offer-content p,
  .offer-data-para {
    font-size: 18px;
    font-weight: 400;
    text-align: left;
  }
  .offer-data-heading {
    font-size: 24px;
    font-weight: 500;
  }
  .notify-sec h3 {
    font-size: 24px;
    font-weight: 400 !important;
    text-align: center;
  }
}

.description1 {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 42px;
}

.completed-items {
  margin-top: 4em;
}
#completedIcon {
  width: 5em;
  height: 5em;
  height: auto;
}

.submitted-request {
  width: 100vw !important;
  margin-left: -15px;
  display: flex;
  justify-content: center;
  align-self: center;
  text-align: center;
  padding-top: 1em;
  padding-bottom: 1em;
}
.submitted-request {
  width: 100vw !important;
  margin-left: -15px !important;
}

.submitted-request {
  margin: 0% !important;
}

.submitted-request-content {
  width: 90% !important;
  margin: 2% 0% 15% 0% !important;
}
.submitted-request-heading {
  margin-bottom: 0.5em;
  font-size: 2em;
}
.offer-items-row {
  z-index: 99;
  width: 70vw;
  align-self: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 5em;
  margin-left: 15vw;
  margin-right: 15vw;
}
.offer-items-row > * {
  flex: 1 1 10em;
}

.thank-you-data {
  margin-top: 1em;
}

.item-box-row {
  margin: 0% auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  column-gap: 0em;
  align-self: center;
}
.arrow-box {
  margin: 2em auto;
}

#arrow {
  height: auto;
  width: 80px;
}
#imgForIcon2 {
  width: 4em;
  height: 4em;
  height: auto;
}
.discount-box {
  height: auto;

  margin-left: 15vw;
  margin-right: 15vw;
  margin-bottom: 6em;
  padding-top: 2em;
  padding-bottom: 2em;
  background-color: #f3f6fa;
}

.dicount-heading {
  font-size: 1.2em;
}

.dicount-content {
  font-size: 1.2em;
  font-weight: 700;
}

@media only screen and (max-width: 685px) {
  /* For mobile phones: */
  .arrow {
    transform: rotate(90deg);
  }
  .item-box-row {
    display: block;
    text-align: center;
  }
  .offer-items-row {
    flex-direction: column;
  }
  .item-box-row {
    margin-top: 0px;
  }
  .description {
    text-align: left;
  }
}

.text-decoration-none:hover {
  text-decoration: none;
}

.main-select {
  height: 63px;
  -webkit-appearance: initial;
  padding: 1.2em 0.9em 1.2em 2.2em;
  border-radius: 0.4em;
  width: 100%;
  border: none;
  color: #323232 !important;
  margin-right: 1em;
  border: 2px solid var(--pri);
  outline: none;
  background-color: #ececed;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

#select-icon {
  width: 13px;
  height: auto;
  position: absolute;
  right: 1.1em;
  top: 1.9em;
  z-index: 100;
}

.home-page-bg-image {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(../images/bg-head.jpeg);
  min-height: 60vh;
}

#select-icon {
  display: none;
}

.drop-down-sroller{

  overflow:scroll;

}

@media all and (min-width: 992px) {

  .navbar .nav-item .dropdown-menu {
      display: none;
  }
  .navbar .nav-item:hover .dropdown-menu {
        display: block;
    }
  .navbar .nav-item .dropdown-menu {
        margin-top: 0;
        text-align: center;
        border-radius: 0;
        border: 0;
        padding-top: 10px;
        padding-bottom: 0;
    }
  .navbar .nav-item .dropdown-menu .dropdown-item {
        padding: 10px 15px;
    text-align: left;
    }
}

.grid.container {
  margin-top: 5em;
}
.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}
.file-name{
  margin-left: 10px;
  padding: 5px 2px 5px 2px;
  border-radius: 30px;
  border: 1px solid ;
  background-color: white;
  border-color: #2185d0;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
}
@media (max-width: 700px){
  .offer-items{
    grid-template-columns: 1fr 1fr;
  }
}

