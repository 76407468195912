.formGroupConfirm {
    pointer-events: none;
    opacity: 0.6;
    background-color: #ccff99;
}

.mainFormDivAuto {
    margin-bottom: 50px;
}

.nextStep {
    margin-top: 230px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.homeOwnerForm1Step {
    border-radius: 15px;
    pointer-events: none;
    opacity: 0.6;
    background-color: #ccff99;
}

.addButton {
    background: transparent;
    pointer-events: all !important;
    border: none;
    margin-left: 15px;
}
.addButton:focus{
    outline: none !important;
}

.addSpan {
    font-size: 18px;
}
.errorSpan{
    font-size: 16px;
    color: red;
    font-weight: 400;
}
.requireSpan{
    margin-left: 3px;
    color: red;
}
.iconFa {
    pointer-events: all !important;
    cursor: pointer;
    margin-left: 15px;
}

.editSpan {
    font-size: 18px;
    margin-left: 35px;
}

.motoForm {
    pointer-events: none;
}

.reduxDeleteButton {
    width: 5px;
    height: 5px;
    background-color: rebeccapurple;
}

.form-inputMoto {
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    margin: 0;
    width: 100%;
    outline: 0;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    tap-highlight-color: rgba(255, 255, 255, 0);
    line-height: 1.21428571em;
    padding: .67857143em 1em;
    font-size: 1em;
    background: #fff;
    border: 1px solid rgba(34, 36, 38, .15);
    color: rgba(0, 0, 0, .87);
    border-radius: .28571429rem;
    box-shadow: 0 0 0 0 transparent inset;
    transition: color .1s ease, border-color .1s ease;
    padding-left: .5em;
}
.aboutUsImges{
    width: 100px;
}
.haveViolation{
    margin-left: 8px;
}
.reduxFromLiMoto {
    display: -webkit-flex;
    flex-direction: row;
    margin: 0 -2.7em 1em;
}
.removeReplace{
    width: 100%;
    display: flex;
    justify-content: center;
}
.removeReplaceText{
    font-weight: 600;
    font-size: 18px;
    margin-top: 10px;
}
.registeredOwner{
    margin-top: 10px;
    font-size: 15px;
}
.attachFile{
    outline: none;
    border: none;
    margin-top: 15px;
}
.attachFile:focus{
    outline: none;
    border: none;
}
.reduxFromLiMoto>select{
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    padding: .67857143em 1em;
    width: 100%;
    background: white;
    box-shadow: 0 1px 3px -2px #9098A9;
    cursor: pointer;
    font-family: inherit;
    font-size: 16px;
    transition: all 150ms ease;
    color: #5A667F;
    border: 1px solid rgba(34, 36, 38, .15) !important;
    border-radius: .28571429rem !important;
}

.reduxFormLabel{
    display: block;
    margin: 0 0 .28571429rem;
    color: rgba(0,0,0,.87);
    font-size: 1.15em;
    font-weight: 700;
}
.requestIcon{
    cursor: pointer;
    margin-left: 10px;
}
.reduxFormLabel2{
    text-transform: uppercase;
    display: block;
    margin: 0 0 .28571429rem;
    color: rgba(0,0,0,.87);
    font-size: 1em;
    font-weight: 700;
}
input[type="date"]{
    width: 40%;
}
.selectOptions{
    width: 100%;
}
.mainDiv>select{
    -moz-appearance: none;
    -webkit-appearance: none;
    padding: 8px 40px 7px 12px;
    width: 100%;
    border: 1px solid #E8EAED;
    border-radius: 5px;
    box-shadow: 0 1px 3px -2px #9098A9;
    cursor: pointer;
    font-family: inherit;
    font-size: 16px;
    transition: all 150ms ease;
    background-color: white !important;
    background: url('../icon_arrow.png') no-repeat right;
    color: #5A667F;
    border: 1px solid rgba(34, 36, 38, .15) !important;
    border-radius: .28571429rem !important;
}
.mainDiv{
    width: 80%;
    padding-left:0.5em;
    padding-right:0.5em
}
.selectOptions::after {
    content: "▼";
    width: 25px;
    height: 20px;
    background:#fff;
    display: block;
    position: absolute;
    text-align:center;
    color:#999;
    font-size:14px;
    top: 5px;
    right: 0;
    z-index: 1;
}
.mainDiv>option{
    cursor: pointer;
}
.violation{
    font-size: 1.15em;
    font-weight: 700;
}
.radioButton{
    width: 25px;
    cursor: pointer;
    margin-left: 15px;
}
.deleteButton{
    font-size: 18px;
}
.driver_container{
    margin-bottom: 100px;
}
.textareaNotResize{
    resize: none;
    height: 100px !important;
}
.checkboxMainDiv{
    display: flex;
    justify-content: center;
    margin-top: 35px;
}
.documentRequest{
    margin-left: 20px;
    font-size: 16px;
    font-weight: 700;
}
.labelWidth{
    width: 200px;
}
.reqBut{
    display: flex;
    margin-top: 50px;
    justify-content: center;
}
.otherChnageTextArea{
    display: flex;
    justify-content: center;
    margin-top: 15px;
}
.otherChnageTextArea>textarea{
    outline: none;
    border: 1px solid grey;
    width: 300px;
    height: 100px;
    resize: none;
}
.options{
    display: flex;
    justify-content: center;
}
.textAreaForm{
    display: flex;
    justify-content: center;
}
@media screen and (max-width:600px) {
    .reduxFromLiMoto{
        display: block;
        margin:0 auto;
    }
    ul{
        padding: 0;
    }
    .mainDiv{
        width: 100%;
        padding: 0;
        margin-top: 15px;
    }
    .haveViolation{
        margin-top: 15px;
    }
    .request{
        display: flex;
        justify-content: center;
    }
    .request>button{
        width: 300px;
    }
    .addDriver{
        display: flex;
        justify-content: center;
    }
    .addSpan{
        font-size: 15px;
    }
    .deleteButton{
        font-size: 15px;
    }
}

.accessibility_p{
    font-size: 23px;
    text-align: center;
    width: 70%;
}
.accessibility_p_main{
    font-weight: 700;
    font-size: 33px;
    text-align: center;
    width: 70%;
}
.accessibility_div{
    margin-top: 50px;
    display: flex;
    justify-content: center;
}
.accessibility_div_2{
    margin-top: 50px;
    display: flex;
    justify-content: center;
    margin-bottom: 70px;
}
.privacy_info_main{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.privacy_info{
    width: 80%;
    font-size: 16px;
}
.apllyBTN{
    margin-top: 20px;
    background:#28a745 ;
}
.apllyBTN:hover{
    border: 1px solid white;
}
.iconsAboutUs{
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-around;
}
.addressChangeHeading{
    font-size: 15px;
    font-weight: 600;
}
.newReviews {
    border-width: 80%;
    padding: 10px;
}
.newReviews_current{
    border-left: 5px solid lightgray;
    font-size: 16px;
    font-family: inherit;
    padding: 10px;
}
.review_creds{
    margin-top: 7px;
    font-size: 14px !important;
    color: grey !important;
}
.reviewsMain{
    display: flex;
    justify-content: center;
}
.reviewsContantMain{
    width: 70%;
    margin-top: 50px;
    margin-bottom: 50px;
}
.reviewsContantMain > blockquote {
    margin-top: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.5;
}
.changeRequestHeading{
    color: #016ab0;
}
.requestChangeCurrent{
    display: flex;
    justify-content: center;
}
.driverFormTop{
    margin-top: 150px;
}
.requestChangeHeading{
    font-size: 25px;
    font-weight: 700;
    margin-top: 100px;
}
.main-div-position{
    margin-top: 50px;
}
.main_request_div_position{
    display: flex;
    justify-content: space-around;
    margin-bottom: 50px;
    margin-top: 70px;
}
.nextPrevRequestButtonMainDiv{
    display: flex;
    justify-content: center;
}
.disclaimorSubmit[type="checkbox"],
.disclaimorSubmit[type="radio"] {
    position: absolute;
    opacity: 0;
    z-index: -1;
}
.disclaimorLabel {
    position: relative;
    display: inline-block;
    padding: 0 0 0 2em;
    height: 1.5em;
    line-height: 1.5;
    cursor: pointer;
}
.disclaimorLabel::before,
.disclaimorLabel::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 1.5em;
    height: 1.5em;
}
.disclaimorLabel::before {
    content: " ";
    border: 2px solid #bdc3c7;
    border-radius: 20%;
}
/* Checkbox */
.disclaimorSubmit[type="checkbox"] + .disclaimorLabel::after {
    content: "\2714";
    color: green;
    line-height: 1.5;
    text-align: center;
}
/* Radio */
.disclaimorSubmit[type="radio"] + .disclaimorLabel::before {
    border-radius: 50%;
}
.disclaimorSubmit[type=radio] + .disclaimorLabel::after {
    content: " ";
    top: .25em;
    left: .25em;
    width: 1em;
    height: 1em;
    background: #fff;
    border: .2em solid #2c3e50;
    border-radius: 50%;
}
/* :checked */
.disclaimorSubmit[type="checkbox"]:checked + .disclaimorLabel::before,
.disclaimorSubmit[type="radio"]:checked + .disclaimorLabel::before {
    background: #fff;
    border-color: #fff;
}
.disclaimorSubmit[type="checkbox"] + .disclaimorLabel::after,
.disclaimorSubmit[type=radio] + .disclaimorLabel::after {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
}
.disclaimorSubmit[type="checkbox"]:checked + .disclaimorLabel::after,
.disclaimorSubmit[type=radio]:checked + .disclaimorLabel::after {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}
/* Transition */
.disclaimorLabel::before,
.disclaimorLabel::after {
    -webkit-transition: .25s all ease;
    -o-transition: .25s all ease;
    transition: .25s all ease;
}
.disclaimorMain{
    margin-top: 25px;
}
@media (max-width: 777px){
    .requestChangeHeading{
        font-size: 20px;
    }
    .iconsAboutUs{
        display: grid;
        grid-template-columns: 1fr 1fr ;
    }
    .main_request_div_position{
        display: grid;
        grid-gap: 10px;
    }
    .driverFormTop{
        margin-top: 50px;
    }
}